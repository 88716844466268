<template>
  <v-row class="app-invoice-preview">
    <!-- <v-spacer></v-spacer>
    <template v-if="apierror.status == 'error'">
      <div v-for="msg in apierror.messages" :key="msg">
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template> -->
    <v-col cols="12" md="9">
      <v-card id="printArea">
        <v-card-text style="padding: 32px 36px;line-height: 1.3rem;">
          <v-row>
            <v-col cols="12" style="font-weight: 500;padding-bottom: 0;margin-bottom: 0;">
              <div style="margin-top: 30px;text-align: center;">
                <span style="color: #rgba(0, 0, 0, 0.87); font-weight: 700; font-size: 1.7rem; line-height:1.6rem;padding-left: 12px;">
                  {{ $t('Quotation.Quotation') }}
                </span>
              </div>
              <div style="width: 100%;display: flex;justify-content: end;">
                <div style="display: flex;flex-direction: column;">
                  <div>
                    {{ $t('Quotation.QuotationCode') }} :
                  </div>
                  <div>
                    {{ $t('Quotation.QuotationDate') }}:
                  </div>
                </div>
                <div style="display: flex;flex-direction: column;text-align: end;width: 100px;">
                  <div>
                    {{ selectedQuotation.quot_code }}
                  </div>
                  <div>
                    {{ selectedQuotation.quot_dt ? selectedQuotation.quot_dt.substring(0, 10) : '' }}
                  </div>
                </div>
              </div>
              <div style="border:1px solid #000; margin: 10px 0 10px 0;padding-bottom: 0;"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" style="font-weight: 500;padding-top: 0;">
              <table>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Sold To:
                  </td>
                  <td width="600">
                    {{ Selectedlanguage === 'en' ? Form.cust_name_eng : Form.cust_name }}
                  </td>
                </tr>
                <!-- <div style="padding-left: 12px;font-size:0.8 rem;">
                {{ Form.cust_name_eng }}
              </div> -->
                <!-- <div style="padding-left: 12px;padding-top:3px">
                {{ Form.zip }}
              </div> -->
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Address:
                  </td>
                  <td width="600">
                    <div v-if="Selectedlanguage === 'en'">
                      {{ Form.address2_eng }}
                    </div>
                    <div v-else>
                      {{ Form.postcode }},  {{ Form.country_name }},
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="90">
                  </td>
                  <td width="600">
                    {{ Selectedlanguage === 'en' ? Form.address1_eng : Form.address1 }}
                  </td>
                </tr>
                <tr>
                  <td width="90">
                  </td>
                  <td width="600">
                    <div v-if="Selectedlanguage === 'en'">
                      {{ Form.country_name_eng }}, {{ Form.postcode }},
                    </div>
                    <div v-else>
                      {{ Form.address2 }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Tel:
                  </td>
                  <td width="600">
                    ({{ Form.country_code }}) {{ Form.tel }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                  </td>
                  <td width="600">
                    ({{ Form.country_code }}) {{ Form.mobile }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    E-mail:
                  </td>
                  <td width="600">
                    {{ Form.email }}
                  </td>
                </tr>
              </table>

              <div style="padding:32px 0 0 12px;">
                <div style="display: inline-block;font-size:1.0rem;font-weight: 800;border: 1px solid #000;width: 160px;">
                  <div style="margin: 10px;">
                    {{ $t('Quotation.Amount') }}
                  </div>
                </div>
                <div style="display: inline-block;font-size:1.0rem;font-weight: 800;border: 1px solid #000;border-left: 0;width: 160px;">
                  <div style="margin: 10px;text-align: right;">
                    &nbsp;{{ Form.ccy_mark }} {{ formatPrice(Form.quot_f_amount) }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="5" style="flex-direction: column;padding-left: 0;">
              <!-- <div style="display: flex;justify-content: space-between;">
                <div style="display: inline;margin-left: 35%;">
                  見積No:
                </div>
                <div style="display: inline;">
                  98765234552
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: inline;margin-left: 35%;">
                  見積日:
                </div>
                <div style="display: inline;">
                  2023-5-22
                </div>
              </div> -->
              <v-row align="center">
                <!-- <v-col cols="3" class="py-0">
                  <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: 10px 0 0 0;" />
                </v-col> -->
                <v-col cols="12" style="display: flex;justify-content:end; text-align:center;padding-bottom: 0;align-items: center;">
                  <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: 0 0 0 0;" />
                  <div style="font-size:1.0rem;padding-left: 24px;font-weight: 800;">
                    株式会社グリーン・アース
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="padding-top: 0;padding-left: 0;">
                  <div style="display: flex;justify-content:end;">
                    〒306-0616
                  </div>
                  <div style="display: flex;justify-content:end;">
                    <span style="max-width: 220px; text-align: end;">{{ $t('GreenEarth.Address') }}</span>
                  </div>

                  <div style="display: flex;justify-content:end;">
                    TEL: 0297-44-4338
                  </div>
                  <div style="display: flex;justify-content:end;">
                    FAX: 0297-44-4335
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-simple-table class="purchased-items-table" style="margin-top: 40px;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="text-align: left;">
                    {{ $t('Quotation.Code') }}
                  </th>
                  <th style="text-align: left;">
                    {{ $t('Quotation.ItemInfo') }}
                  </th>
                  <!-- <th style="text-align: left;">
                    説明
                  </th> -->
                  <th style="text-align: right;">
                    {{ $t('Quotation.Qty') }}
                  </th>
                  <th style="text-align: right;">
                    {{ $t('Quotation.Unit') }}(KG/F)
                  </th>
                  <th style="text-align: right;">
                    {{ $t('Quotation.Price') }}({{ Form.ccy_code }}/KG)
                  </th>
                  <th style="text-align: right;">
                    {{ $t('Quotation.Weight') }}(KG)
                  </th>
                  <th style="text-align: right;">
                    {{ $t('Quotation.Amount') }}({{ Form.ccy_code }})
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">
                    1
                  </td>
                  <td style="text-align: left;">
                    {{ Form.po_code }} ( {{ Form.pellet_id }} ) <br />
                    <div>{{ NameByLang(Form) }}</div>
                  </td>
                  <!-- <td style="text-align: left;">
                    {{ Form.description }}
                  </td> -->
                  <td style="text-align: right;">
                    {{ formatData(Form.resv_cnt) }}
                  </td>

                  <td style="text-align: right;">
                    {{ formatData(Form.po_weight) }}
                  </td>
                  <td style="text-align: right;">
                    {{ PriceByCurrency(Form) }}
                  </td>
                  <td style="text-align: right;">
                    {{ formatData(Form.resv_weight) }}
                  </td>
                  <td style="text-align: right;">
                    {{ formatAmount(Form.ccy_mark,Form.resv_amount) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Total -->
          <div class="tableTotal">
            <div>
              <table style="width: 100%;font-weight:bold;">
                <tr>
                  <td style="padding-right: 64px;">
                    {{ $t('Quotation.Subtotal') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ Form.ccy_mark }} {{ formatPrice(Form.quot_o_amount) }}
                  </th>
                </tr>
                <tr>
                  <td style="padding-right: 64px;">
                    {{ $t('Quotation.Discount') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ formatPrice(Form.quot_d_amount) }}
                  </th>
                </tr>
                <!-- <tr>
                  <td style="padding-right: 74px;">
                    消費税:
                  </td>
                  <th style="text-align: right;">
                    21%
                  </th>
                </tr> -->
              </table>
              <v-divider style="margin: 12px 0 12px;"></v-divider>
              <table style="width: 250px;font-weight:bold;">
                <tr>
                  <td style="padding-right: 64px;">
                    {{ $t('Quotation.Amount') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ Form.ccy_mark }} {{ formatPrice(Form.quot_f_amount) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
        <v-divider style="margin: 0 32px 0;"></v-divider>

        <v-card-text style="padding:16px 36px;margin-top:auto;">
          <span class="font-weight-semibold">{{ $t('Quotation.Note') }}: </span>
          <div style="line-height: 1.4rem;font-weight: 500;">
            <div>
              <div style="display: inline;">
                {{ $t('Quotation.Expiration') }}
              </div>
              <div style="display: inline;">
                {{ Form.quot_dd }}
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card class="mb-6">
        <v-card-text>
          <div class="d-flex justify-end">
            <Language>
            </Language>
          </div>

          <v-btn color="primary" class="mb-3" block @click="printContract">
            Print
          </v-btn>
          <v-btn color="secondary" block outlined @click="loadData()">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiMinusCircle,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

import printHtml from '@/utils/print'
import Language from '@/components/language.vue'

export default {
  components: {

    Language,
  },

  setup() {
    const printContract = () => {
      const printArea = document.getElementById('printArea').innerHTML
      printHtml(printArea)
    }

    const showContract = lang => {
      console.log('lang:', lang)
    }

    return {

      // Icons
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,

      },

      printContract,
      showContract,
    }
  },
  data: () => ({
    Form: {

    },
    details: [],
    Selectedlanguage: '',
  }),
  computed: {
    ...mapState('reservedStore', ['selectedQuotation']),

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    PriceByCurrency() {
      return function (Form) {
        if (Form.ccy_id === 1) {
          const price = 0.001 * Form.po_price_usd

          return Form.ccy_mark + price.toFixed(3).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return Form.po_price
      }
    },

    NameByLang() {
      return function (Form) {
        if (localStorage.getItem('lang') === 'zh') {
          return Form.po_name_cn
        }

        if (localStorage.getItem('lang') === 'en') {
          return Form.po_name_en
        }

        return Form.po_name_ja
      }
    },
    OvvByLang() {
      return function (Form) {
        if (localStorage.getItem('lang') === 'zh') {
          return Form.item_ovv_cn
        }

        if (localStorage.getItem('lang') === 'en') {
          return Form.item_ovv_en
        }

        return Form.item_ovv_ja
      }
    },

  },
  updated() {
    this.Selectedlanguage = localStorage.getItem('lang')
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('reservedStore', ['loadQuotation']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadQuotation(this.$route.params.id).then(() => {
        this.Form = this.selectedQuotation
        console.log('quotation:', this.Form)
      }).catch(error => {
        console.log('error:', error)
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        console.log('selectedQuotation', this.selectedQuotation)
        this.setOverlayStatus(false)
      })
    },
  },

  getName(Form) {
    if (localStorage.getItem('lang') === 'zh') {
      return Form.item_ovv_cn
    }

    if (localStorage.getItem('lang') === 'en') {
      return Form.item_ovv_en
    }

    return Form.item_ovv_ja
  },
}
</script>

  <style scoped>
    #printArea {
    line-height: 1.5rem !important;
    /* width:794px; */
    height:1400px;
    }
    .contractTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    }
    .purchased-items-table th{
    background-color: transparent !important;
    }
    .purchased-items-table tr:last-child td{
    border-bottom: thin solid #E0E0E0;
    }
    .tableTotal {
    display: flex;
    flex-direction: row;
    margin: 24px 12px 0 12px;
    justify-content: end;
    }
  </style>
